import  useGetData  from "../hooks/useGetData";
const API_BASE_URL = "http://chores-backend-production.up.railway.app/api";




const DailyChores = (props) => {
const { data: chores, pending, error } = useGetData(`${API_BASE_URL}/daily`, "daily");
const kids = props.kids;

    return ( 
        <div>
             {pending && <div className="lds-ripple"><div></div><div></div></div>}
            {error && <p className="error"> {error}</p>}
            {chores && 
            chores.map((item) => (
                <div className="chore-div" key={item.id}>
                    <h1 id="name"> {kids[item.id -1  ].name}</h1>
                    <div  className="chore-description chore-card-daily">
                        <p id="title"> {item.title}</p>

                        <ul>
                            {item.tasks.map((elem) => (
                                <li key={elem.id}>{elem.description}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))} 
                        
        </div>
     );
}
 
export default DailyChores;