
import './App.css';
import './media.css'
import Home from "./pages/main"
function App() {
  return (
    <div className="App">
     <Home />
    </div>
  );
}

export default App;