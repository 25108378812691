import useGetData from "../hooks/useGetData";

const DishChores = (props) => {
    const {data:chores,pending,error} = useGetData('http://chores-backend-production.up.railway.app/api/dish', "dishes");
    const {family} = props;
   
    return (  
        <div>
         {pending && <div class="lds-ripple"><div></div><div></div></div>}
        {error && <p className="error"> {error}</p>}
        {chores && 
        chores.map((item) => (
            <div className="chore-div" id="dish">
                <h1 id="name"> {family[item.id-1].name}</h1>
                <div className="chore-description">
                    <p id="title"key={item.id}> {item.title}</p>
                </div>
            </div>
        ))} 
        
         
      
          
        
    </div>
    );
}
 
export default DishChores;