import { useState, useEffect } from "react";

const useGetData = (url, type) => {
  const [pending, setPending] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    
   setTimeout(() =>{
    fetch(url, {method:"GET", credentials:'include'})
    .then(res => {
     
      if(!res.ok){
        throw Error("could not fetch the data for that resource");
      }
     
      return res.json();
    })
    .then(data =>{ 
      
      setPending(false);
      setError(null);
      if(type === "daily" ){
      setData(data.daily);
      }else if(type ==="dishes"){
      setData(data.dish);
      }else if(type === "kids" || type === "family"){
      setData(data.member);
      }else{
        setData(data)
      }
      

     
    })
    .catch(err =>{
      setPending(false);
      setError(err.message);
    })
   },2000)
  }, [url,type]);
  
  return { data, pending, error };
};

export default useGetData;
